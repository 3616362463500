import { collection, deleteDoc, doc, getDocs, query, setDoc, updateDoc, where, orderBy } from '@firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from '@firebase/storage';
import React, { useEffect, useState } from 'react'
import Lister from '../../Components/Helper/Lister';
import Loader from '../../Components/Helper/Loader';
import Year from '../../Components/Helper/Year';
import { db, storage } from '../../Firebase/config';

function EventPage() {

  useEffect(() => {
    fetchEvents()
  }, [])
  
  const [events, setEvents] = useState([])
  const [loader, setLoader] = useState(false)
  const [addNew, setAddNew] = useState(false)
  const [edit, setEdit] = useState(false)
  const [editId, setEditId] = useState('')
  const [currYear, setCurrYear] = useState(2022)
  const [title, setTitle] = useState('')
  const [desc, setDesc] = useState('')
  const [reg_link, setReg_link] = useState('')
  const [date, setDate] = useState('')
  const [place, setPlace] = useState('MITS Varikoli')
  const [venue, setVenue] = useState('')
  const [price, setPrice] = useState('')
  const [type, setType] = useState('')
  const [poster, setPoster] = useState('')

  const fetchEvents= async (year=new Date().getFullYear())=>{
    const querySnapshot = await getDocs(query(collection(db, "events"),where('year','==',year),orderBy('Date','desc')));
    let events=[]
    querySnapshot.forEach((doc) => {
      events.push(doc.data())
    });
    setEvents(events)
    setLoader(false)
  }
  const handleYearClick = (year)=>{
		setLoader(true)
		fetchEvents(year)
    setCurrYear(year)
  }
  const handleAddNew = ()=>{
		setAddNew(!addNew)
  }
  function getRandomString(length) {
    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for ( var i = 0; i < length; i++ ) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
	}
  const handleImageUpload = (e)=>{
    setLoader(true)
		const img = e.target.files[0]
		uploadImage(img)
	}
  const uploadImage = (img)=>{
		const storageRef = ref(storage, `execom/${getRandomString(10)}`);
		uploadBytes(storageRef, img).then((snapshot) => {
      setLoader(false)
			getDownloadURL(snapshot.ref).then((url)=>{setPoster(url)})
		});
	}
  const removePhoto = ()=>{
		setPoster('')
	}
  const handleSubmit = ()=>{
		setLoader(true)
		const docData = {
			Title:title,
			Register_link:reg_link,
			Primary_description:desc,
			Date:date,
			Place:place,
			Venue:venue,
      Price:price,
      Type:type,
			Event_Poster:poster,
      year:(new Date(date)).getFullYear()
		}
		if(addNew){
			setDoc(doc(collection(db, "events")), docData).then(()=>{setLoader(false)});
			setAddNew(false)
		}else if(edit){
			updateDoc(doc(db,'events',editId),docData).then(()=>{setLoader(false)})
			setEdit(false)
			fetchEvents(currYear)
		}
	}
  const editItem = async (id,date)=>{
		const q = query(collection(db,'events'),where('Title','==',id),where('Date','==',date))
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach((doc) => {
			setTitle(doc.data().Title)
			setReg_link(doc.data().Register_link)
			setDesc(doc.data().Primary_description)
			setDate(doc.data().Date)
			setPlace(doc.data().Place)
			setVenue(doc.data().Venue)
			setPrice(doc.data().Price)
      setType(doc.data().Type)
      setPoster(doc.data().Event_Poster)
			setEditId(doc.ref.id)
			setEdit(true)
		});
	}
  const deleteItem = async (id,date)=>{
		setLoader(true)
		const q = query(collection(db,'events'),where('Title','==',id),where('Date','==',date))
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach((doc) => {
			deleteDoc(doc.ref)
		})
		fetchEvents(currYear)
		setLoader(false)
	}
  return (
    <div className='events-page-top box-top'>
      {!addNew && !loader && !edit?
        <React.Fragment>
          <div className="addNew">
            <button onClick={handleAddNew}>Add New</button>
          </div>
          <div className="member-det">
            <p className='row-heading'>SI </p>
            <p className='row-heading'>Title</p>
            <p className='row-heading d-sm-none'>Description</p>
            <p className='row-heading d-sm-none'>Date</p>
            <p className='row-heading'>Action</p>
          </div>
          {loader && <Loader/>}
          {events.map((event,index)=>
            <Lister col1={event.Title} col2={event.Primary_description.slice(0,40)} col3={new Date(event.Date).toLocaleString()} index={index+1} col4={event.Date} id={event.Title} editItem={editItem} deleteItem={deleteItem}/>
          )}
          {events.length===0 && <p>No events added</p>}
          <Year handleClick={handleYearClick} curYear={currYear}/>
        </React.Fragment>
        :
        loader?
        <Loader/>
        :
        <React.Fragment>
          <div className="addNew-top">
            <div className="addNew-qstn">
              <label htmlFor="name">Title</label>
              <input type="text" className="addNew-name" id="name" onChange={(e)=>{setTitle(e.target.value)}} value={title} required />
            </div>
            <div className="addNew-qstn">
              <label htmlFor="branch">Register Link</label>
              <input type="text" className="addNew-branch" id="branch" onChange={(e)=>{setReg_link(e.target.value)}} value={reg_link} required />
            </div>
            <div className="addNew-qstn">
              <label htmlFor="email">Description</label>
              <textarea name="desc" id="" cols="30" rows="30" onChange={(e)=>{setDesc(e.target.value)}} value={desc}></textarea>
            </div>
            <div className="addNew-qstn">
              <label htmlFor="email">Date and Time</label>
              <input type="datetime-local" name="" id="" onChange={(e)=>{setDate(e.target.value)}} value={date}/>
            </div>
            <div className="addNew-qstn">
              <label htmlFor="branch">Place</label>
              <input type="text" className="addNew-branch" id="branch" onChange={(e)=>{setPlace(e.target.value)}} value={place} required />
            </div>
            <div className="addNew-qstn">
              <label htmlFor="branch">Venue</label>
              <input type="text" className="addNew-branch" id="branch" onChange={(e)=>{setVenue(e.target.value)}} value={venue} required />
            </div>
            <div className="addNew-qstn">
              <label htmlFor="branch">Price</label>
              <input type="text" className="addNew-branch" id="branch" onChange={(e)=>{setPrice(e.target.value)}} value={price} required />
            </div>
            <div className="addNew-qstn">
              <label htmlFor="">Type</label>
              <select name="" id="" className="addNew-society" value={type} onChange={(e)=>{setType(e.target.value)}} required>
                <option className="slct-option" value='Meeting' >Meeting</option>
                <option value="Competition" className="slct-option">Competition</option>
                <option value="Talk" className="slct-option">Talk</option>
              </select>
            </div>
            <div className="addNew-qstn">
              <label htmlFor="">Event Poster</label>
              {poster? <p onClick={removePhoto}>Remove Photo</p> : <input type="file" onChange={handleImageUpload} className="addNew-photo" />}
            </div>
            <div className="sub-button">
              <button onClick={()=>{
                  setAddNew(false) 
                  setEdit(false)
                }}>Cancel
              </button>
              <input type="submit" onClick={handleSubmit} className="sub-inpt" />
            </div>          
          </div>
        </React.Fragment>
      }
    </div>
  )
}

export default EventPage