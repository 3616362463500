import './App.css';
import Home from './Components/Execom/Execom'
import {
  Routes,
  Route
} from "react-router-dom";
import { useNavigate } from 'react-router';
import Execom from './Components/Execom/Execom';
import EventPage from './Pages/Event/EventPage';
import Gallery from './Pages/Gallery/Gallery';
import Achievements from './Pages/Achievements/Achievements';
import Login from './Pages/Login/Login';
import { getAuth, onAuthStateChanged } from '@firebase/auth';
import { useEffect } from 'react';
function App() {
  const auth = getAuth();
  const nav = useNavigate()

  useEffect(() => {
    if(window.location.pathname!=='/login'){
      console.log('auth chck')  
      onAuthStateChanged(auth, (user) => {
        if (!user) {
          nav('/login')
        }
      })
    }
  },[])
  
  return (
    <div className={window.location.pathname!='/login'?'App':'App-Login'}>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/execom' element={<Execom />} />
        <Route path='/events' element={<EventPage />} />
        <Route path='/gallery' element={<Gallery />} />
        <Route path='/achievements' element={<Achievements />} />
        <Route path='/login' element={<Login />} />
      </Routes>
    </div>
  );
}

export default App;
