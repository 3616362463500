import { collection, deleteDoc, doc, getDocs, query, setDoc, updateDoc, where } from '@firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from '@firebase/storage';
import React, { useEffect, useState } from 'react'
import Lister from '../../Components/Helper/Lister';
import Loader from '../../Components/Helper/Loader';
import Year from '../../Components/Helper/Year';
import { db, storage } from '../../Firebase/config';

function Gallery() {

  useEffect(() => {
    fetchImages()
  }, [])

  const [images, setImages] = useState([])
  const [loader, setLoader] = useState(false)
  const [currYear, setCurrYear] = useState(2022)
  const [addNew, setAddNew] = useState(false)
  const [Content, setContent] = useState('')
  const [image, setImage] = useState('')
  const [edit, setEdit] = useState(false)
  const [editId, setEditId] = useState('')

  const fetchImages= async (year=new Date().getFullYear())=>{
    const querySnapshot = await getDocs(query(collection(db, "gallery"),where('year','==',year)));
    let images=[]
    querySnapshot.forEach((doc) => {
      images.push(doc.data())
    });
    setImages(images)
    setLoader(false)
  }
  const handleYearClick = (year)=>{
		setLoader(true)
		fetchImages(year)
    setCurrYear(year)
  }
  const handleAddNew = ()=>{
    setAddNew(true)
  }
  function getRandomString(length) {
    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for ( var i = 0; i < length; i++ ) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
	}
  const handleImageUpload = (e)=>{
		const img = e.target.files[0]
		uploadImage(img)
	}
  const uploadImage = (img)=>{
		const storageRef = ref(storage, `gallery/${getRandomString(10)}`);
		uploadBytes(storageRef, img).then((snapshot) => {
			getDownloadURL(snapshot.ref).then((url)=>{setImage(url)})
		});
	}
  const removePhoto = ()=>{
		setImage('')
	}
  const handleSubmit = ()=>{
		setLoader(true)
		const docData = {
			Content:Content,
			Images:image,
      date:(new Date()).toLocaleDateString(),
      year:(new Date()).getFullYear()
		}
		if(addNew){
			setDoc(doc(collection(db, "gallery")), docData).then(()=>{setLoader(false)});
			setAddNew(false)
		}else if(edit){
			updateDoc(doc(db,'gallery',editId),docData).then(()=>{setLoader(false)})
			setEdit(false)
			fetchImages(currYear)
		}
    resetState()
	}
  const editItem = async (id,year)=>{
    console.log(id,year)
		const q = query(collection(db,'gallery'),where('Images','==',id),where('year','==',year))
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach((doc) => {
			setContent(doc.data().Content)
      setImage(doc.data().Images)
      setEditId(doc.ref.id)
      setEdit(true)
		});
	}
  const deleteItem = async (id,year)=>{
		setLoader(true)
		const q = query(collection(db,'gallery'),where('Content','==',id),where('year','==',year))
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach((doc) => {
			deleteDoc(doc.ref)
		})
		fetchImages(currYear)
		setLoader(false)
	}
  const resetState = ()=>{
    setContent('')
    setImage('')
  }
  return (
    <div className="gallery-top box-top">
      {!addNew && !edit && !loader?
        <React.Fragment>
          <div className="addNew">
            <button onClick={handleAddNew}>Add New</button>
          </div>
          <div className="member-det">
            <p className='row-heading'>SI </p>
            <p className='row-heading'>Images</p>
            <p className='row-heading d-sm-none'>Alt text</p>
            <p className='row-heading d-sm-none'>Date</p>
            <p className='row-heading'>Action</p>
          </div>
          
          {images.map((image,index)=>
            <Lister image={image.Images} col2={image.Content && image.Content.slice(0,40)} col3={image.date ? image.date : image.year} index={index} col4={image.year} id={image.Images} editItem={editItem} deleteItem={deleteItem}/>
          )}
          {images.length===0 && <p>No events added</p>}
          <Year handleClick={handleYearClick} curYear={currYear}/>
        </React.Fragment>
      :
        loader ?
          <Loader />
        :
          <React.Fragment>
            <div className="addNew-top">
              <div className="addNew-qstn">
                <label htmlFor="name">Alt Text</label>
                <input type="text" className="addNew-name" id="name" onChange={(e)=>{setContent(e.target.value)}} value={Content} required />
              </div>
              <div className="addNew-qstn">
                <label htmlFor="">Image</label>
                {image? <p onClick={removePhoto}>Remove Photo</p> : <input type="file" onChange={handleImageUpload} className="addNew-photo" />}
              </div>
              <div className="addNew-qstn">
                {image && <img src={image} alt={Content} /> }
              </div>
              <div className="sub-button">
                <button 
                  onClick={()=>{
                    setAddNew(false) 
                    setEdit(false)
                    resetState()
                  }}
                >Cancel</button>
                <input type="submit" onClick={handleSubmit} className="sub-inpt" />
              </div>          
            </div>
          </React.Fragment>
      }
    </div>
  )
}

export default Gallery