import { initializeApp} from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage} from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBbqwLx3O60cmEOI7_YV6Uklzaa-kMNRmo",
  authDomain: "ieee-website-c6c5c.firebaseapp.com",
  projectId: "ieee-website-c6c5c",
  storageBucket: "ieee-website-c6c5c.appspot.com",
  messagingSenderId: "870320346209",
  appId: "1:870320346209:web:3c747897422e526bf04620",
  measurementId: "G-8SQHBCCEWE"
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore();
export const storage = getStorage();
