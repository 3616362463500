import { getAuth, signOut } from '@firebase/auth';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router';
import { useLocation } from "react-router-dom";

import './Sidebar.css'

function Sidebar(props) {
	const nav = useNavigate();
	const [navOpen, setNavOpen] = useState(false)
	const location = useLocation();

  useEffect(() => {
    setNavOpen(false)
  }, [location]);
	

	
	const handleSignout = ()=>{
		const auth = getAuth();
		signOut(auth).then(() => {
				nav('/login')
		}).catch((error) => {
				alert('Try again!')
		});
	}

	const openMobileNav = ()=>{
		setNavOpen(!navOpen)
	}
	return (
		<div className='sidebar-top sidebar-mobile-top' style={{display:window.location.pathname !== '/login' ? 'flex': 'none'}}>
				<div className="menu-but">
					<div id="nav-icon1" className={navOpen ? 'open':''} onClick={openMobileNav}>
							<span></span>
							<span></span>
							<span></span>
					</div>
					<div id="nav-icon2" className='hidden-ham'>
							<span></span>
							<span></span>
							<span></span>
					</div>
				</div>
				<div className={`sidebar sidebar-mobile ${!navOpen ? 'hide-navbar':'show-navbar'}`}>
						<div className={`${window.location.pathname === '/'?"nav-active":""} sidebar-links`}>
								<Link to='/' state={{prevPath : window.location.pathname}}>Home</Link>
						</div>
						<div className={`${window.location.pathname === '/execom'?"nav-active":""} sidebar-links`}>
								<Link to='/execom' state={{prevPath : window.location.pathname}}>Execom</Link>
						</div>
						<div className={`${window.location.pathname === '/events'?"nav-active":""} sidebar-links`}>
								<Link to='/events' state={{prevPath : window.location.pathname}}>Events</Link>
						</div>
						<div className={`${window.location.pathname === '/achievements'?"nav-active":""} sidebar-links`}>
								<Link to='/achievements' state={{prevPath : window.location.pathname}}>Achievements</Link>
						</div>
						<div className={`${window.location.pathname === '/gallery'?"nav-active":""} sidebar-links`}>
								<Link to='/gallery' state={{prevPath : window.location.pathname}}>Gallery</Link>
						</div>
						<div className="sidebar-links">
								<p style={{margin:0,color:'#00a1ff',fontWeight:'bold'}} onClick={handleSignout}>Sign Out</p>
						</div>
				</div>
		</div>
	)
}

export default Sidebar