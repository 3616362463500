import { useEffect, useState } from "react"

function Year(props) {
  const years = []
	for(let i=parseInt(new Date().getFullYear());i>=2018;i--){
		years.push(i)
	}
	
	
	const [selected, setSelected] = useState(props.curYear)
	const handleClick = (yr)=>{
		console.log(yr)
		setSelected(yr)
	}
	useEffect(() => {
		if(!props.curYear){
			setSelected(2022)
		}
	}, [])
  return (
    <div className='year-top'>
			<div className="year-box">
				{years.map(year=>
				<p style={{color:selected===year?'rgb(10, 117, 189)':'inherit'}}
				onClick={(e)=>{
					props.handleClick(year) 
					handleClick(year)
				}} className='year-key'>{year}
				</p>
			)}
			</div>
		</div>
  )
}

export default Year