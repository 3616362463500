import { collection, deleteDoc, doc, getDocs, query, setDoc, updateDoc, where } from '@firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from '@firebase/storage';
import React, { useEffect, useState } from 'react'
import Lister from '../../Components/Helper/Lister';
import Loader from '../../Components/Helper/Loader';
import Year from '../../Components/Helper/Year';
import { db, storage } from '../../Firebase/config';

function Achievements() {
  useEffect(() => {
    fetchAchieves()
  }, [])
  const initialState= {
    date:'',
    event:'',
    winners:{first:'',second:'',third:''},
    year:'',
    poster:''
  }
  const [achievs, setAchievs] = useState([])
  const [loader, setLoader] = useState('')
  const [currYear, setCurrYear] = useState(2022)
  const [addNew, setAddNew] = useState(false)
  const [edit, setEdit] = useState(false)
  const [editId, setEditId] = useState('')
  const [docData, setdocData] = useState(initialState)
  
  
  const fetchAchieves = async(year = (new Date()).getFullYear())=>{
    const querySnapshot = await getDocs(query(collection(db, "winners"),where('year','==',year)));
    let achieves=[]
    querySnapshot.forEach((doc) => {
      achieves.push(doc.data())
    });
    setAchievs(achieves)
    setLoader(false)
  }
  const handleYearClick = (year)=>{
		setLoader(true)
		fetchAchieves(year)
    setCurrYear(year)
  }
  const handleAddNew = ()=>{
    setAddNew(true)
  }
  const editItem = async(id,year)=>{
    console.log(id,year)
		const q = query(collection(db,'winners'),where('event','==',id),where('year','==',year))
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach((doc) => {
			setdocData(doc.data())
      setEditId(doc.ref.id)
      setEdit(true)
		});
  }
  const deleteItem = async (id,year)=>{
		setLoader(true)
		const q = query(collection(db,'winners'),where('event','==',id),where('year','==',year))
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach((doc) => {
			deleteDoc(doc.ref)
		})
		fetchAchieves(currYear)
		setLoader(false)
	}
  const handleChange = (e,sub)=>{
    if(sub){
      const { name, value } = e.target;
      setdocData(prevState => ({
        ...prevState,
        winners: {...prevState.winners,[name]:value}
      }));
    }else{
      const { name, value } = e.target;
      setdocData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  }
  const handleSubmit = ()=>{
    setLoader(true)
		let data = docData
    data.year = (new Date(data.date)).getFullYear() 
		if(addNew){
			setDoc(doc(collection(db, "winners")), data).then(()=>{setLoader(false)});
			setAddNew(false)
		}else if(edit){
			updateDoc(doc(db,'winners',editId),data).then(()=>{setLoader(false)})
			setEdit(false)	
		}
    fetchAchieves(currYear)
    setdocData(initialState)
  }
  function getRandomString(length) {
    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for ( var i = 0; i < length; i++ ) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
	}
  const handleImageUpload = (e)=>{
		const img = e.target.files[0]
		uploadImage(img)
	}
  const uploadImage = (img)=>{
		const storageRef = ref(storage, `winners/${getRandomString(10)}`);
		uploadBytes(storageRef, img).then((snapshot) => {
			getDownloadURL(snapshot.ref).then((url)=>{setdocData({...docData,poster:url})})
		});
	}
  const removePhoto = ()=>{
		setdocData({...docData,poster:''})
	}
  return (
    <div className="box-top achiv-top">
      {!addNew && !edit && !loader ?
        <React.Fragment>
          <div className="addNew">
            <button onClick={handleAddNew}>Add New</button>
          </div>
          <div className="member-det">
            <p className='row-heading'>SI </p>
            <p className='row-heading'>Event Name</p>
            <p className='row-heading d-sm-none'>Date</p>
            <p className='row-heading d-sm-none'>Winner</p>
            <p className='row-heading'>Action</p>
          </div>
          {achievs.map((event,index)=>
            <Lister col1={event.event} col2={(new Date(event.date)).toLocaleDateString()} col3={event.winners.first} index={index} col4={event.year} id={event.event} editItem={editItem} deleteItem={deleteItem}/>
          )}
          {loader.length===0 && <p>No events added</p>}
          <Year handleClick={handleYearClick} curYear={currYear}/>
        </React.Fragment>
      :
        loader ?
          <Loader />
        :
          <React.Fragment>
            <div className="addNew-top">
              <div className="addNew-qstn">
                <label htmlFor="name">Event Name</label>
                <input type="text" className="addNew-name" id="name" name='event' onChange={handleChange} value={docData.event} required />
              </div>
              <div className="addNew-qstn">
                <label htmlFor="name">Event Date</label>
                <input type="datetime-local" className="addNew-name" id="name" name='date' onChange={handleChange} value={docData.date} required />
              </div>
              <div className="addNew-qstn">
                <label htmlFor="name">Winners</label>
                {Object.keys(docData.winners).map((index)=>
                  <input type="text" className="addNew-name" style={{marginBottom:'2em'}} placeholder={index} name={index} onChange={(e)=>{handleChange(e,true)}} value={docData.winners[index]} required />
                )}
              </div>
              <div className="addNew-qstn">
                <label htmlFor="">Event Poster</label>
                {docData.poster? <p onClick={removePhoto}>Remove Photo</p> : <input type="file" onChange={handleImageUpload} className="addNew-photo" />}
              </div>
              <div className="addNew-qstn">
                {docData.poster && <img src={docData.poster} /> }
              </div>
              <div className="sub-button">
                <button 
                  onClick={()=>{
                    setAddNew(false) 
                    setEdit(false)
                    setdocData(initialState)
                  }}
                >Cancel</button>
                <input type="submit" onClick={handleSubmit} className="sub-inpt" />
              </div>          
            </div>
          </React.Fragment>
      }
    </div>
  )
}

export default Achievements