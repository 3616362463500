import '../Execom/Execom.css'

function Lister({col1,col2,col3,col4,index,editItem,deleteItem,id,image,alt}) {
	
  return (
    <div className='execom-top'>
			<div className="execom">
				<div className="member-item">
					<div className="member-det">
						<div className='lister-item-box '>
						<p>{index}</p>
						</div>
						<div className='lister-item-box '>
						{image ? 
							<img src={image} alt={alt} />
						:
							<p>{col1}</p>
						}
						</div>
						<div className='lister-item-box d-sm-none'>
						<p>{col2}</p>
						</div>
						<div className='lister-item-box d-sm-none'>
						<p>{col3}</p>
						</div>
						<div className='lister-item-box actions-icons'>
						<img onClick={()=>{editItem(id,col4)}} src="https://img.icons8.com/external-kiranshastry-solid-kiranshastry/64/000000/external-edit-interface-kiranshastry-solid-kiranshastry-1.png" alt='edit'/>
						<img onClick={()=>{deleteItem(id,col4)}} src="https://img.icons8.com/ios-glyphs/30/000000/trash--v1.png" alt='trash'/>
						</div>
					</div>
				</div>
			</div>
    </div>
  )
}

export default Lister
